<template>
  <b-card title="Actualizar tipo de vehículo">
    <b-row>
      <b-col md="2">
        <div class="form-group">
          <label>Código - ID</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="id"
            disabled
            placeholder="Descripción"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div class="form-group">
          <label>Descripción</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="form.descripcion"
            placeholder="Descripción"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <div class="d-flex justify-content-end">
          <router-link
            :to="{ name: 'vehicleType' }"
            class="btn btn-sm btn-circle btn-link mr-3"
          >
            <i class="la la-reply"></i> Regresar
          </router-link>
          <button
            class="btn btn-sm btn-circle btn-success"
            @click="update()"
            v-if="can('update-vehicle-type')"
          >
            <i class="flaticon-edit-1"></i>
            {{ loading ? "Cargando..." : "Actualizar" }}
          </button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import can from "@/permission";
import axios from "axios";
import toastr from "toastr";
export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      form: {
        descripcion: null,
      },
    };
  },
  mounted() {
    this.loadVehicleType();
  },
  methods: {
    can: can,
    loadVehicleType() {
      axios
        .get(`/vehiculo-tipos/${this.id}`)
        .then((res) => {
          const data = res.data;
          this.form = data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    update() {
      if (this.form.descripcion) {
        this.loading = true;
        axios
          .put(`/vehiculo-tipos/${this.id}`, this.form)
          .then((res) => {
            if (res.data.message == "success") {
              toastr.success(res.data.information);
              this.loadVehicleType();
            } else {
              toastr.info(res.data.information);
            }
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      } else {
        toastr.info(`El campo descripción es obligatorio`);
      }
    },
  },
};
</script>
